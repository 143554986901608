<template>
    <el-select v-model="periodIndex" popper-class="period-select-2" placeholder="Månader i aktuell period väljare" class="w-60" @change="periodSelectChanged" :disabled="disabled">
        <el-option v-for="period in periodDataComputed" :key="period.localId" :label="period.name" :value="period.localId" />
    </el-select>
</template>
<script>
import queryString from "query-string";
import moment from "moment";

export default {
    props: {
        periodData: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        periodDataComputed() {
            return this.periodData.map((item, index) => {
                item.localId = index;
                return item;
            });
        },
    },

    watch: {
        periodData() {
            if (!this.periodData.length) return;
            const { startDate, endDate } = queryString.parse(location.search);

            const existingDate = this.periodDataComputed.filter(item => {
                return item.startDate === startDate && item.endDate === endDate;
            });

            if (existingDate.length === 1) {
                this.periodIndex = existingDate[0].localId;
            }
        },
    },

    data() {
        return {
            periodIndex: null,
        };
    },

    methods: {
        async periodSelectChanged() {
            const period = this.periodDataComputed.filter(item => item.localId === this.periodIndex)[0];
            this.$emit("selected", period);

            const query = queryString.parse(location.search);
            query.startDate = moment(period.startDate).format("YYYY-MM-DD");
            query.endDate = moment(period.endDate).format("YYYY-MM-DD");
            await this.$router.replace({ query }).catch(() => {});
            this.$emit("dateSelectionChanged");
        },

        resetState() {
            this.periodIndex = null;
        },
    },
};
</script>
<style>
.period-select-2 .el-select-dropdown__wrap {
    max-height: 450px !important;
}
</style>
